* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans";
}

@font-face {
  font-family: 'Ubuntu Mono';
  src: url('../public/fonts/UbuntuMono/UbuntuMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu Mono';
  src: url('../public/fonts/UbuntuMono/UbuntuMono-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu Mono';
  src: url('../public/fonts/UbuntuMono/UbuntuMono-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu Mono';
  src: url('../public/fonts/UbuntuMono/UbuntuMono-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Space Mono';
  src: url('../public/fonts/SpaceMono/SpaceMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Space Mono';
  src: url('../public/fonts/SpaceMono/SpaceMono-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Space Mono';
  src: url('../public/fonts/SpaceMono/SpaceMono-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Space Mono';
  src: url('../public/fonts/SpaceMono/SpaceMono-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

.headshot-image {
  width: 200px;
  height: auto;
  display: block;
  border-radius: 2%;
}